// openapi-fetch: 0.8.2
// https://github.com/openapi-ts/openapi-typescript/blob/openapi-fetch%400.8.2/packages/openapi-fetch/src/index.js
//
// This code is being included in the repo because its `for of` loops are not
//  correctly transpiled by babel; said `for of` loops have been commented out
//  because they are unneccessary for the time being.
//
/* eslint-disable */

// settings & const
const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

/**
 * Create an openapi-fetch client.
 * @type {import("./index.js").default}
 */
// @ts-ignore
export default function createClient(clientOptions) {
  const {
    fetch: baseFetch = globalThis.fetch,
    querySerializer: globalQuerySerializer,
    bodySerializer: globalBodySerializer,
    ...baseOptions
  } = clientOptions ?? {};
  let baseUrl = baseOptions.baseUrl ?? '';
  if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.slice(0, -1); // remove trailing slash
  }

  /**
   * Per-request fetch (keeps settings created in createClient()
   * @param {string} url
   * @param {import('./index.js').FetchOptions} fetchOptions
   */
  // @ts-ignore
  async function coreFetch(url, fetchOptions) {
    const {
      fetch = baseFetch,
      headers,
      body: requestBody,
      params = {},
      parseAs = 'json',
      querySerializer = globalQuerySerializer ?? defaultQuerySerializer,
      bodySerializer = globalBodySerializer ?? defaultBodySerializer,
      ...init
    } = fetchOptions || {};

    // URL
    const finalURL = createFinalURL(url, {
      baseUrl,
      params,
      querySerializer,
    });
    const finalHeaders = mergeHeaders(
      DEFAULT_HEADERS,
      clientOptions?.headers,
      headers,
      params.header
    );

    // fetch!
    const requestInit = {
      redirect: 'follow',
      ...baseOptions,
      ...init,
      headers: finalHeaders,
    };

    if (requestBody) {
      requestInit.body = bodySerializer(requestBody);
    }
    // remove `Content-Type` if serialized body is FormData; browser will correctly set Content-Type & boundary expression
    if (requestInit.body instanceof FormData) {
      finalHeaders.delete('Content-Type');
    }

    const response = await fetch(finalURL, requestInit);

    // handle empty content
    // note: we return `{}` because we want user truthy checks for `.data` or `.error` to succeed
    if (response.status === 204 || response.headers.get('Content-Length') === '0') {
      return response.ok ? { data: {}, response } : { error: {}, response };
    }

    // parse response (falling back to .text() when necessary)
    if (response.ok) {
      // if "stream", skip parsing entirely
      if (parseAs === 'stream') {
        // fix for bun: bun consumes response.body, therefore clone before accessing
        return { data: response.clone().body, response };
      }
      const cloned = response.clone();
      return {
        data: typeof cloned[parseAs] === 'function' ? await cloned[parseAs]() : await cloned.text(),
        response,
      };
    }

    // handle errors (always parse as .json() or .text())
    let error = {};
    try {
      error = await response.clone().json();
    } catch {
      error = await response.clone().text();
    }
    return { error, response };
  }

  return {
    /** Call a GET endpoint */
    // @ts-ignore
    async GET(url, init) {
      return coreFetch(url, { ...init, method: 'GET' });
    },
    /** Call a PUT endpoint */
    // @ts-ignore
    async PUT(url, init) {
      return coreFetch(url, { ...init, method: 'PUT' });
    },
    /** Call a POST endpoint */
    // @ts-ignore
    async POST(url, init) {
      return coreFetch(url, { ...init, method: 'POST' });
    },
    /** Call a DELETE endpoint */
    // @ts-ignore
    async DELETE(url, init) {
      return coreFetch(url, { ...init, method: 'DELETE' });
    },
    /** Call a OPTIONS endpoint */
    // @ts-ignore
    async OPTIONS(url, init) {
      return coreFetch(url, { ...init, method: 'OPTIONS' });
    },
    /** Call a HEAD endpoint */
    // @ts-ignore
    async HEAD(url, init) {
      return coreFetch(url, { ...init, method: 'HEAD' });
    },
    /** Call a PATCH endpoint */
    // @ts-ignore
    async PATCH(url, init) {
      return coreFetch(url, { ...init, method: 'PATCH' });
    },
    /** Call a TRACE endpoint */
    // @ts-ignore
    async TRACE(url, init) {
      return coreFetch(url, { ...init, method: 'TRACE' });
    },
  };
}

// utils

/**
 * Serialize query params to string
 * @type {import("./index.js").defaultQuerySerializer}
 */
// @ts-ignore
export function defaultQuerySerializer(q) {
  // @ts-ignore
  const search = [];
  if (q && typeof q === 'object') {
    Object.entries(q).forEach(([k, v]) => {
      const value = defaultQueryParamSerializer([k], v);
      if (value) {
        search.push(value);
      }
    });
  }
  // @ts-ignore
  return search.join('&');
}

/**
 * Serialize query param schema types according to expected default OpenAPI 3.x behavior
 * @type {import("./index.js").defaultQueryParamSerializer}
 */
// @ts-ignore
export function defaultQueryParamSerializer(key, value) {
  if (value === null || value === undefined) {
    return undefined;
  }
  if (typeof value === 'string') {
    return `${deepObjectPath(key)}=${encodeURIComponent(value)}`;
  }
  if (typeof value === 'number' || typeof value === 'boolean') {
    return `${deepObjectPath(key)}=${String(value)}`;
  }
  if (Array.isArray(value)) {
    if (!value.length) {
      return undefined;
    }
    // @ts-ignore
    const nextValue = [];
    value.forEach((item) => {
      const next = defaultQueryParamSerializer(key, item);
      if (next !== undefined) {
        nextValue.push(next);
      }
    });
    // @ts-ignore
    return nextValue.join(`&`);
  }
  if (typeof value === 'object') {
    if (!Object.keys(value).length) {
      return undefined;
    }
    // @ts-ignore
    const nextValue = [];
    Object.entries(value).forEach(([k, v]) => {
      if (v !== undefined && v !== null) {
        const next = defaultQueryParamSerializer([...key, k], v);
        if (next !== undefined) {
          nextValue.push(next);
        }
      }
    });
    // @ts-ignore
    return nextValue.join('&');
  }
  return encodeURIComponent(`${deepObjectPath(key)}=${String(value)}`);
}

/**
 * Flatten a node path into a deepObject string
 * @type {import("./index.js").deepObjectPath}
 */
// @ts-ignore
function deepObjectPath(path) {
  let output = path[0];
  // @ts-ignore
  path.slice(1).forEach((k) => {
    output += `[${k}]`;
  });
  return output;
}

/**
 * Serialize body object to string
 * @type {import("./index.js").defaultBodySerializer}
 */
// @ts-ignore
export function defaultBodySerializer(body) {
  return JSON.stringify(body);
}

/**
 * Construct URL string from baseUrl and handle path and query params
 * @type {import("./index.js").createFinalURL}
 */
// @ts-ignore
export function createFinalURL(pathname, options) {
  let finalURL = `${options.baseUrl}${pathname}`;
  if (options.params.path) {
    Object.entries(options.params.path).forEach(([k, v]) => {
      finalURL = finalURL.replace(`{${k}}`, encodeURIComponent(String(v)));
    });
  }
  const search = options.querySerializer(options.params.query ?? {});
  if (search) {
    finalURL += `?${search}`;
  }
  return finalURL;
}

/**
 * Merge headers a and b, with b taking priority
 * @type {import("./index.js").mergeHeaders}
 */
// @ts-ignore
export function mergeHeaders(...allHeaders) {
  const headers = new Headers();
  allHeaders.forEach((headerSet) => {
    if (!headerSet || typeof headerSet !== 'object') {
      // continue
    } else {
      const iterator =
        // @ts-ignore
        headerSet instanceof Headers ? headerSet.entries() : Object.entries(headerSet);
      // @ts-ignore
      iterator.forEach(([k, v]) => {
        if (v === null) {
          headers.delete(k);
        } else if (v !== undefined) {
          headers.set(k, v);
        }
      });
    }
  });
  return headers;
}
