import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';

import { VERSION } from '../version';

import { getOAuthSubjectOrAnonymousId, getUserReadOnly } from '../cookies';

export const initializeSentry = () => {
  const shouldLoadSentry = window.SENTRY_DSN && !getUserReadOnly();
  if (!shouldLoadSentry) return;

  Sentry.init({
    beforeSend: (event) => {
      // Get all cookies as a string
      const cookies = document.cookie;

      // Split the string into individual cookies
      const cookieArray = cookies.split(';');

      // Create a map of cookie names to the length of that cookie's string
      const cookieNameToLength = cookieArray.reduce((acc, cookie) => {
        const [name, value] = cookie.split('=');

        acc[name.trim()] = value ? value.length : 0; // If the cookie has a value, get its length, else 0

        return acc;
      }, {} as Record<string, number>);

      // Attach cookies to every event
      // eslint-disable-next-line no-param-reassign
      event.extra = {
        ...event.extra,
        cookieNameToLength,
      };

      return event;
    },
    dsn: window.SENTRY_DSN,
    release: VERSION,
    integrations: [
      new CaptureConsole({
        levels: window.DEBUG_LOGGING_TO_SENTRY
          ? ['debug', 'assert', 'log', 'neutral', 'warn', 'error']
          : ['error'],
      }),
    ],
  });

  const oauthSub = getOAuthSubjectOrAnonymousId();
  Sentry.setUser({ id: JSON.stringify(oauthSub) });
};
