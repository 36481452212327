// NOTE: This was separated from utils to fix a circular dependency issue.

const getCookieValue = (key: string) => {
  const cookies = document.cookie;
  let pos = cookies.indexOf(key);

  if (pos >= 0) {
    // Add "1" to account for "=" sign in cookie text
    let value = cookies.substring(pos + key.length + 1);
    pos = value.indexOf(';');
    if (pos >= 0) value = value.substring(0, pos);
    return value.length > 0 ? value : null;
  }
  return null;
};

export const getEscapedUserFullName = () => getCookieValue('automed_user_name') ?? '';
export const getUserFullName = () =>
  // When 'Doe, John' becomes a cookie value, it becomes '"Doe\054 John"'. Turn it back into 'Doe, John'.
  getEscapedUserFullName().replace(/\\054/g, ',').replace(/"/g, '');

// Return the current automed session's oauth subject, or '' if none is available.
export const getOAuthSubject = () => getCookieValue('automed_oauth_subject') ?? '';

export const getUserReadOnly = () => {
  const readOnly = getCookieValue('automed_read_only');
  return readOnly === 'true';
};

export const getUserRegardAdmin = () => {
  const isRegardAdmin = getCookieValue('automed_regard_admin');
  return isRegardAdmin === 'true';
};

const anonymousId = `anonymous${Math.random() * 10000000}`;

export const getOAuthSubjectOrAnonymousId = () => getOAuthSubject() || anonymousId;
