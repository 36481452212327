/* eslint-disable */
import smoothscroll from './smoothscroll';

// https://github.com/iamdustan/smoothscroll
smoothscroll.polyfill();

/* IE10 does not support Element.closest(), but carries support for
 * a prefixed equivalent of element.matches()
 * Source: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest */
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

/* IE10 does not support CustomEvent constructor we need to create
  * manual "change" events for the MeshedNote TextArea, so we polyfill */
(function () {
  if (typeof window === 'undefined') {
    return;
  }

  try {
    var ce = new window.CustomEvent('test', { cancelable: true });
    ce.preventDefault();
    if (ce.defaultPrevented !== true) {
      // IE has problems with .preventDefault() on custom events
      // http://stackoverflow.com/questions/23349191
      throw new Error('Could not prevent default');
    }
  } catch (e) {
    var CustomEvent = function (event, params) {
      var evt, origPrevent;
      params = params || {};
      params.bubbles = !!params.bubbles;
      params.cancelable = !!params.cancelable;

      evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(
        event,
        params.bubbles,
        params.cancelable,
        params.detail
      );
      origPrevent = evt.preventDefault;
      evt.preventDefault = function () {
        origPrevent.call(this);
        try {
          Object.defineProperty(this, 'defaultPrevented', {
            get: function () {
              return true;
            }
          });
        } catch (e) {
          this.defaultPrevented = true;
        }
      };
      return evt;
    };

    CustomEvent.prototype = window.Event.prototype;
    window.CustomEvent = CustomEvent; // expose definition to window
  }
})();

// Inject polyfill if classList not supported for SVG elements.
// Source: https://github.com/tobua/svg-classlist-polyfill/blob/master/polyfill.js
if (!('classList' in SVGElement.prototype)) {
  Object.defineProperty(SVGElement.prototype, 'classList', {
    get: function get() {
      var _this = this

      return {
        contains: function contains(className) {
          return _this.className.baseVal.split(' ').indexOf(className) !== -1
        },
        add: function add(className) {
          return _this.setAttribute(
            'class',
            _this.getAttribute('class') + ' ' + className
          )
        },
        remove: function remove(className) {
          var removedClass = _this
            .getAttribute('class')
            .replace(
              new RegExp('(\\s|^)'.concat(className, '(\\s|$)'), 'g'),
              '$2'
            )

          if (_this.classList.contains(className)) {
            _this.setAttribute('class', removedClass)
          }
        },
        toggle: function toggle(className) {
          if (this.contains(className)) {
            this.remove(className)
          } else {
            this.add(className)
          }
        }
      }
    }
  })
}


// Prevent the backspace key from navigating back.
(function () {
  var backspaceCode = 8
  var validInputTypes = {
    TEXT: 1, PASSWORD: 1, FILE: 1, EMAIL: 1, SEARCH: 1, DATE: 1, NUMBER: 1,
    MONTH: 1, WEEK: 1, TIME: 1, DATETIME: 1, 'DATETIME-LOCAL': 1, TEL: 1, URL: 1
  }

  function connectedToTheDom(node) {
    // IE does not have contains method on document element, only body   
    var container = node.ownerDocument.contains ? node.ownerDocument : node.ownerDocument.body;
    return container.contains(node);
  }

  function isInActiveContentEditable(node) {
    while (node) {
      if (node.getAttribute &&
        node.getAttribute("contenteditable") &&
        node.getAttribute("contenteditable").toUpperCase() === "TRUE") {
        return true
      }
      node = node.parentNode
    }
    return false
  }

  function isActiveFormItem(node) {
    var tagName = node.tagName.toUpperCase()
    var isInput = (tagName === "INPUT" && node.type.toUpperCase() in validInputTypes)
    var isTextarea = (tagName === "TEXTAREA")
    if (isInput || isTextarea) {
      var isDisabled = node.readOnly || node.disabled
      return !isDisabled && connectedToTheDom(node)  // the element may have been disconnected from the dom between the event happening and the end of the event chain, which is another case that triggers history changes
    }
    else if (isInActiveContentEditable(node)) {
      return connectedToTheDom(node)
    }
    else {
      return false
    }
  }

  function backspaceDisabler(event) {
    if (event.keyCode === backspaceCode) {
      var node = event.srcElement || event.target
      // We don't want to disable the ability to delete content in form inputs and contenteditables
      if (!isActiveFormItem(node)) {
        event.preventDefault()
      }
    }
  }

  document.addEventListener("keydown", backspaceDisabler, false);
})();

// https://github.com/aFarkas/requestIdleCallback/blob/master/index.js
(function (factory) {
  if (typeof define === 'function' && define.amd) {
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    module.exports = factory();
  } else {
    window.idleCallbackShim = factory();
  }
}(function(){
  'use strict';
  var scheduleStart, throttleDelay, lazytimer, lazyraf;
  var root = typeof window != 'undefined' ?
    window :
    typeof global != undefined ?
      global :
      this || {};
  var requestAnimationFrame = root.cancelRequestAnimationFrame && root.requestAnimationFrame || setTimeout;
  var cancelRequestAnimationFrame = root.cancelRequestAnimationFrame || clearTimeout;
  var tasks = [];
  var runAttempts = 0;
  var isRunning = false;
  var remainingTime = 7;
  var minThrottle = 35;
  var throttle = 125;
  var index = 0;
  var taskStart = 0;
  var tasklength = 0;
  var IdleDeadline = {
    get didTimeout(){
      return false;
    },
    timeRemaining: function(){
      var timeRemaining = remainingTime - (Date.now() - taskStart);
      return timeRemaining < 0 ? 0 : timeRemaining;
    },
  };
  var setInactive = debounce(function(){
    remainingTime = 22;
    throttle = 66;
    minThrottle = 0;
  });

  function debounce(fn){
    var id, timestamp;
    var wait = 99;
    var check = function(){
      var last = (Date.now()) - timestamp;

      if (last < wait) {
        id = setTimeout(check, wait - last);
      } else {
        id = null;
        fn();
      }
    };
    return function(){
      timestamp = Date.now();
      if(!id){
        id = setTimeout(check, wait);
      }
    };
  }

  function abortRunning(){
    if(isRunning){
      if(lazyraf){
        cancelRequestAnimationFrame(lazyraf);
      }
      if(lazytimer){
        clearTimeout(lazytimer);
      }
      isRunning = false;
    }
  }

  function onInputorMutation(){
    if(throttle != 125){
      remainingTime = 7;
      throttle = 125;
      minThrottle = 35;

      if(isRunning) {
        abortRunning();
        scheduleLazy();
      }
    }
    setInactive();
  }

  function scheduleAfterRaf() {
    lazyraf = null;
    lazytimer = setTimeout(runTasks, 0);
  }

  function scheduleRaf(){
    lazytimer = null;
    requestAnimationFrame(scheduleAfterRaf);
  }

  function scheduleLazy(){

    if(isRunning){return;}
    throttleDelay = throttle - (Date.now() - taskStart);

    scheduleStart = Date.now();

    isRunning = true;

    if(minThrottle && throttleDelay < minThrottle){
      throttleDelay = minThrottle;
    }

    if(throttleDelay > 9){
      lazytimer = setTimeout(scheduleRaf, throttleDelay);
    } else {
      throttleDelay = 0;
      scheduleRaf();
    }
  }

  function runTasks(){
    var task, i, len;
    var timeThreshold = remainingTime > 9 ?
      9 :
      1
    ;

    taskStart = Date.now();
    isRunning = false;

    lazytimer = null;

    if(runAttempts > 2 || taskStart - throttleDelay - 50 < scheduleStart){
      for(i = 0, len = tasks.length; i < len && IdleDeadline.timeRemaining() > timeThreshold; i++){
        task = tasks.shift();
        tasklength++;
        if(task){
          task(IdleDeadline);
        }
      }
    }

    if(tasks.length){
      scheduleLazy();
    } else {
      runAttempts = 0;
    }
  }

  function requestIdleCallbackShim(task){
    index++;
    tasks.push(task);
    scheduleLazy();
    return index;
  }

  function cancelIdleCallbackShim(id){
    var index = id - 1 - tasklength;
    if(tasks[index]){
      tasks[index] = null;
    }
  }

  if(!root.requestIdleCallback || !root.cancelIdleCallback){
    root.requestIdleCallback = requestIdleCallbackShim;
    root.cancelIdleCallback = cancelIdleCallbackShim;

    if(root.document && document.addEventListener){
      root.addEventListener('scroll', onInputorMutation, true);
      root.addEventListener('resize', onInputorMutation);

      document.addEventListener('focus', onInputorMutation, true);
      document.addEventListener('mouseover', onInputorMutation, true);
      ['click', 'keypress', 'touchstart', 'mousedown'].forEach(function(name){
        document.addEventListener(name, onInputorMutation, {capture: true, passive: true});
      });

      if(root.MutationObserver){
        new MutationObserver( onInputorMutation ).observe( document.documentElement, {childList: true, subtree: true, attributes: true} );
      }
    }
  } else {
    try{
      root.requestIdleCallback(function(){}, {timeout: 0});
    } catch(e){
      (function(rIC){
        var timeRemainingProto, timeRemaining;
        root.requestIdleCallback = function(fn, timeout){
          if(timeout && typeof timeout.timeout == 'number'){
            return rIC(fn, timeout.timeout);
          }
          return rIC(fn);
        };
        if(root.IdleCallbackDeadline && (timeRemainingProto = IdleCallbackDeadline.prototype)){
          timeRemaining = Object.getOwnPropertyDescriptor(timeRemainingProto, 'timeRemaining');
          if(!timeRemaining || !timeRemaining.configurable || !timeRemaining.get){return;}
          Object.defineProperty(timeRemainingProto, 'timeRemaining', {
            value:  function(){
              return timeRemaining.get.call(this);
            },
            enumerable: true,
            configurable: true,
          });
        }
      })(root.requestIdleCallback)
    }
  }

  return {
    request: requestIdleCallbackShim,
    cancel: cancelIdleCallbackShim,
  };
}));

// --- TextEncoder & TextDecoder polyfill ---
// NOTE: Originally included for influx package
if (typeof window['TextEncoder'] !== 'function') {
  require('fast-text-encoding');
}
