/**
 * Yeah
 * @module
 */

type SuccessResult<T> = {
  status: 'success';
  result: T | null;
};
type FailureResult = {
  status: 'failure';
  responseCode: number | 'NetworkError';
  message: string;
};
export type APIResult<T> = SuccessResult<T> | FailureResult;

export const wrapSuccessResult = <T>(result: T): SuccessResult<T> => ({
  status: 'success',
  result,
});

/**
 * Typed async wrapper for JSON requests
 */
export const fetchJson = async <T>(
  request: RequestInfo,
  requestInit?: RequestInit
): Promise<APIResult<T>> => {
  let response;
  try {
    //  A fetch() promise only rejects when a network error is encountered
    //  A fetch() promise does not reject on HTTP errors (404, etc.)
    response = await fetch(request, requestInit);
  } catch (error) {
    return {
      status: 'failure',
      responseCode: 'NetworkError',
      message: 'Network Error',
    };
  }

  if (!response.ok) {
    return {
      status: 'failure',
      responseCode: response.status,
      message: response.statusText,
    };
  }

  // If json() fails to decode the body (e.g., the response is empty), make the result null.
  // This is distinguished from a FailureResult because the request _succeeded_, but the response
  // can't be parsed.
  const json = await response.json().catch(() => null);

  return {
    status: 'success',
    result: json as T | null,
  };
};
